import Lenis from "@studio-freight/lenis";

import $, { event } from "jquery";
import Flip from "gsap/Flip";

import "splitting/dist/splitting.css";
import "splitting/dist/splitting-cells.css";

import { gsap, ScrollTrigger, Draggable, MotionPathPlugin } from "gsap/all";
gsap.registerPlugin(ScrollTrigger, Draggable, Flip, MotionPathPlugin);
import Swiper from "swiper";

import Swiper, {
  Navigation,
  Pagination,
  Autoplay,
  EffectFade,
  Parallax,
  EffectCards,
} from "swiper";
import Ukiyo from "ukiyojs";
Swiper.use([Navigation, Pagination, Autoplay, EffectFade, Parallax]);

import Accordion from "accordion-js";
import "accordion-js/dist/accordion.min.css";
import ready from "@document/ready";
import hideOnScroll from "hide-on-scroll";
import { Loader } from "@googlemaps/js-api-loader";

const lenis = new Lenis({
  duration: 1.2,
  easing: (t) => Math.min(1, 1.001 - Math.pow(2, -10 * t)), // https://www.desmos.com/calculator/brs54l4xou
  direction: "vertical", // vertical, horizontal
  gestureDirection: "vertical", // vertical, horizontal, both
  smooth: true,
  mouseMultiplier: 1,
  smoothTouch: false,
  touchMultiplier: 2,
  infinite: false,
});
const scroll = new hideOnScroll({
  navbarSelector: ".header__box.second",
  hidingClass: "hidden",
  pollingInterval: 0,
});
async function showMapa() {
  const loader = new Loader({
    apiKey: "AIzaSyCRxzmj4eleUyOyQLlz2j7FLxdXYQxmMKw",
    version: "weekly",
  });

  const myLatLng = { lat: 48.7759331, lng: 16.7623628 };

  const mapOptions = {
    center: myLatLng,
    zoom: 15,

    styles: [
      {
        featureType: "all",
        elementType: "labels.text.fill",
        stylers: [
          {
            color: "#000000",
          },
        ],
      },
      {
        featureType: "all",
        elementType: "labels.text.stroke",
        stylers: [
          {
            color: "#ffffff",
          },
        ],
      },
      {
        featureType: "administrative.province",
        elementType: "all",
        stylers: [
          {
            visibility: "on",
          },
        ],
      },
      {
        featureType: "landscape",
        elementType: "all",
        stylers: [
          {
            saturation: "-39",
          },
          {
            lightness: "35",
          },
          {
            gamma: "1.08",
          },
        ],
      },
      {
        featureType: "landscape",
        elementType: "geometry",
        stylers: [
          {
            saturation: "0",
          },
        ],
      },
      {
        featureType: "landscape.man_made",
        elementType: "all",
        stylers: [
          {
            saturation: "-100",
          },
          {
            lightness: "10",
          },
        ],
      },
      {
        featureType: "landscape.man_made",
        elementType: "geometry.stroke",
        stylers: [
          {
            saturation: "-100",
          },
          {
            lightness: "-14",
          },
        ],
      },
      {
        featureType: "poi",
        elementType: "all",
        stylers: [
          {
            saturation: "-100",
          },
          {
            lightness: "10",
          },
          {
            gamma: "2.26",
          },
        ],
      },
      {
        featureType: "poi",
        elementType: "labels.text",
        stylers: [
          {
            saturation: "-100",
          },
          {
            lightness: "-3",
          },
        ],
      },
      {
        featureType: "road",
        elementType: "all",
        stylers: [
          {
            saturation: "-100",
          },
          {
            lightness: "54",
          },
        ],
      },
      {
        featureType: "road",
        elementType: "geometry.stroke",
        stylers: [
          {
            saturation: "-100",
          },
          {
            lightness: "-7",
          },
        ],
      },
      {
        featureType: "road.arterial",
        elementType: "all",
        stylers: [
          {
            saturation: "-100",
          },
        ],
      },
      {
        featureType: "road.local",
        elementType: "all",
        stylers: [
          {
            saturation: "-100",
          },
          {
            lightness: "-2",
          },
        ],
      },
      {
        featureType: "transit",
        elementType: "all",
        stylers: [
          {
            saturation: "-100",
          },
        ],
      },
      {
        featureType: "water",
        elementType: "geometry.fill",
        stylers: [
          {
            saturation: "-100",
          },
          {
            lightness: "100",
          },
        ],
      },
      {
        featureType: "water",
        elementType: "geometry.stroke",
        stylers: [
          {
            saturation: "-100",
          },
          {
            lightness: "-100",
          },
        ],
      },
    ],
    mapTypeControl: false,
    streetViewControl: false,
    scaleControl: true,
    rotateControl: false,
  };
  const image = "../img/beachflag.png";
  loader.load().then(function (google) {
    const svgMarker = {
      path:
        "M5 8.97266C7.25 8.72363 9 6.81641 9 4.5C9 2.01465 6.98535 0 4.5 0C2.01465 0 0 2.01465 0 4.5C0 6.81641 1.75 8.72363 4 8.97266V23H5V8.97266Z",
      fillColor: "#07211f",
      fillOpacity: 1,
      strokeWeight: 0,
      rotation: 0,
      scale: 3,
      anchor: new google.maps.Point(9, 23),
    };

    const map = new google.maps.Map(document.getElementById("map"), mapOptions);

    new google.maps.Marker({
      position: myLatLng,
      map,
      icon: svgMarker,
      title: "Penzion Venkovský dvůr",
    });
  });
}
const promis = document.getElementById("map");

if (promis) {
  showMapa();
}
ready(() => {
  $(window).scroll(function () {
    var scroll = $(document).scrollTop();

    if (scroll > 50) {
      $("body").addClass("is-scrolled");
    }
    if (scroll <= 300) {
      $("body").removeClass("is-scrolled");
    }
  });

  const scrollFn = () => {
    lenis.raf();
    requestAnimationFrame(scrollFn);
  };

  requestAnimationFrame(scrollFn);

  const password = [...document.querySelectorAll(".npt")];
  password.forEach((el) => {
    el.addEventListener("click", (event) => {
      $(el).toggleClass("filled");
    });
  });

  $(".npt__input").focus(function (e) {});
  $(".menuOpen").click(function (e) {
    e.preventDefault(), $("#menuControler").toggleClass("show");
    $("body").toggleClass("active-menu");
  });
  $(".header__box__left").click(function (e) {
    e.preventDefault(), (window.location.href = "/");
  });

  const insideLInks = [...document.querySelectorAll("a:not(.outside)")];
  insideLInks.forEach((el) => {
    el.addEventListener("click", function (event) {
      var linkLocation = $(el).attr("href");
      event.preventDefault();
      gsap.to(".curHolder", {
        ease: "easeInOutQuad",

        onStart: () => {
          $("body").addClass("is-scrolled");
          $(".header__box.second").addClass("hidden");
          $("body").removeClass("loaded");
        },
        duration: 1.2,
        opacity: 1,
        onComplete: function () {
          window.location = linkLocation;
        },
      });
    });
  });

  const slidery = [...document.querySelectorAll(".defaultSlide")];
  slidery.forEach((el) => {
    var mySwiperMain = new Swiper(el, {
      effect: "slide",
      parallax: true,
      speed: 1000,

      spaceBetween: 20,
      grabCursor: true,
      loop: false,

      breakpoints: {
        320: {
          centeredSlides: true,
          slidesPerView: 1.2,
          spaceBetween: 20,
          centeredSlides: true,
          followFinger: false,
        },

        900: {
          slidesPerView: 2.5,
          spaceBetween: 12,
          centeredSlides: false,
          followFinger: true,
        },
        1600: {
          slidesPerView: 2.8,
          spaceBetween: 12,
          centeredSlides: false,
          followFinger: true,
        },
      },

      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
    });
  });

  const slideryP = [...document.querySelectorAll(".pokoje")];
  slideryP.forEach((el) => {
    var mySwiperMain = new Swiper(el, {
      effect: "slide",
      parallax: true,
      speed: 1000,
      slidesPerView: 2,
      spaceBetween: 20,
      grabCursor: true,
      loop: false,
      followFinger: true,
      // breakpoints: {
      //   320: {
      //     centeredSlides: true,
      //     slidesPerView: 1.2,
      //     spaceBetween: 20,
      //     centeredSlides: true,
      //   },

      //   900: {
      //     slidesPerView: 2.5,
      //     spaceBetween: 12,
      //     centeredSlides: false,
      //   },
      //   1600: {
      //     slidesPerView: 2.8,
      //     spaceBetween: 12,
      //     centeredSlides: false,
      //   },
      // },

      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
    });
  });

  var mySwiperMainLand = new Swiper(".swiperLand", {
    effect: "fade",
    parallax: false,
    speed: 1000,

    grabCursor: true,
    loop: false,

    breakpoints: {
      320: {
        centeredSlides: true,
        slidesPerView: 1,

        centeredSlides: true,
      },

      900: {
        slidesPerView: 1,
        spaceBetween: 12,
        centeredSlides: true,
      },
      1600: {
        slidesPerView: 1,

        centeredSlides: true,
      },
    },

    pagination: {
      el: ".swiper-pagination",
      clickable: true,
      renderBullet: function (index, className) {
        return '<span class="' + className + '">' + (index + 1) + "</span>";
      },
    },
  });

  const swiper3 = [...document.querySelectorAll(".swiper3")];
  if (swiper3) {
    var mySwiperMain3 = new Swiper(".swiper3", {
      effect: "slide",
      parallax: false,
      speed: 1000,
      followFinger: false,
      loop: true,
      breakpoints: {
        320: {
          slidesPerView: 1.2,
          spaceBetween: 12,
          centeredSlides: true,
        },

        900: {
          slidesPerView: 3,
          spaceBetween: 12,
          centeredSlides: false,
        },
        1600: {
          slidesPerView: 3,
          spaceBetween: 12,

          centeredSlides: false,
        },
      },
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      pagination: {
        el: ".swiper-pagination",
        type: "progressbar",
      },
    });
  }

  // const menuScroll = [...document.querySelectorAll(".header__box")];
  // menuScroll.forEach((el) => {
  //   gsap.to(el, {
  //     scrollTrigger: {
  //       trigger: el,
  //       start: "top top",

  //       scrub: true,
  //       markers: true,
  //     },

  //     translateY: "-100%",
  //     opacity: 1,
  //   });
  // });

  const zoomText = [...document.querySelectorAll(".textBig")];
  zoomText.forEach((el) => {
    gsap.set(el, { scale: 1, opacity: 0, scale: 0.8 }),
      gsap.to(el, {
        scrollTrigger: {
          trigger: el,
        },
        ease: "ExpoScaleEase",

        scale: 1,
        translateY: "0%",
        opacity: 1,
        duration: 0.8,
      });
  });

  const button = [...document.querySelectorAll(".btnAnium")];
  button.forEach((el) => {
    gsap.set(el, { scale: 1, opacity: 0, scale: 1.1 }),
      gsap.to(el, {
        scrollTrigger: {
          trigger: el,
        },
        ease: "ExpoScaleEase",

        scale: 1,
        translateY: "0%",
        opacity: 1,
        duration: 1.2,
      });
  });
  const iamge = [...document.querySelectorAll(".swiper")];
  iamge.forEach((el) => {
    gsap.set(el, { opacity: 0 }),
      gsap.to(el, {
        scrollTrigger: {
          trigger: el,
        },
        ease: "ExpoScaleEase",

        opacity: 1,

        duration: 0.8,
      });
  });

  const headline = [...document.querySelectorAll(".titleLine .line")];
  headline.forEach((el) => {
    gsap.to(el, {
      scrollTrigger: {
        trigger: el,
      },
      ease: "ExpoScaleEase",
      onStart: () => {
        $(el).addClass("anim");
      },

      duration: 0.8,
    });
  });
  // const onStart = [...document.querySelectorAll(".onStart .line")];
  // onStart.forEach((el) => {
  //   gsap.to(el, {
  //     ease: "ExpoScaleEase",
  //     onStart: () => {
  //       $(el).addClass("anim");
  //     },
  //     opacity: 1,

  //     duration: 0.8,
  //   });
  // });
  const animIM = [...document.querySelectorAll(".svgHolder")];
  animIM.forEach((el) => {
    gsap.set(el, { scale: 0.5 }),
      gsap.to(el, {
        scrollTrigger: {
          trigger: el,
        },
        ease: "ExpoScaleEase",

        scale: 1.2,

        opacity: 1,
        duration: 2.2,
      });
  });
  const imwrao = [...document.querySelectorAll(".imwrao")];
  imwrao.forEach((el) => {
    gsap.set(el, { scale: 0.8 }),
      gsap.to(el, {
        scrollTrigger: {
          trigger: el,
          scrub: true,
        },
        ease: "ExpoScaleEase",

        scale: 1,

        opacity: 1,
        duration: 2.2,
      });
  });
  // window.addEventListener("load", function () {});

  // window.addEventListener("resize", function () {
  //   mySwiperMain.reInit();
  // });

  const boxAnim = [...document.querySelectorAll(".setColor")];
  boxAnim.forEach((el) => {
    const itemColor = el.getAttribute("data-curtain");
    const bg = el.getAttribute("data-bg");
    const colorCurtain = [...document.querySelectorAll("." + itemColor)];
    console.log(colorCurtain);

    gsap.set(colorCurtain, {
      // background: bg,
      opacity: 0,
      background:
        "-webkit-radial-gradient(circle," + bg + " 0%, rgba(0,0,0,0) 5%)",
    });

    gsap.to(colorCurtain, {
      scrollTrigger: {
        trigger: el,
        start: "top bottom",
        end: "top center",

        scrub: true,
      },
      ease: "ExpoScaleEase",
      // onComplete: () => {
      //   document.body.style.setProperty("--textColor", text);
      //   document.body.style.setProperty("--bgColor", bg);
      // },

      opacity: 1,
      background:
        "-webkit-radial-gradient(circle," + bg + " 0%, " + bg + " 100%)",
      stagger: {
        each: 0.1,
        dutation: 1.5,
        repeat: 0,
      },
    });
    // gsap.to("body", { ease: "ExpoScaleEase", background: bg });
  });

  function setColor(bg, text) {
    console.log(bg);
  }

  const parala = [...document.querySelectorAll("[data-position]")];
  parala.forEach((el) => {
    const posArray = el.getAttribute("data-position").split(" ");
    var unit = "%";

    var target = el.getElementsByTagName("img");
    if (posArray[1]) {
      var to = posArray[1];
      var end = "top center";
    } else {
      var to = -Math.abs(posArray[0]);
    }

    if (posArray[0] == "50vh") {
      var from = window.innerHeight / 2;
      unit = "";
    } else {
      var from = posArray[0];
    }

    if (posArray[3]) {
      unit = posArray[3];
    }
    // console.log(posArray[0]);
    // console.log(posArray[1]);

    // console.log(negative);
    gsap.set(el, {
      opacity: 1,
      scale: 1,
      translateY: from + unit,
      // width: width + "%",
    }),
      gsap.to(target, {
        scrollTrigger: {
          trigger: target,
          scrub: true,
          start: "top bottom",
          end: "bottom top",
          // markers: true,
        },
        scale: 1,
        translateY: to + unit,
        duration: 1.2,
        stagger: {
          each: 0.2,

          repeat: 0,
        },
      });
  });

  $("body").addClass("loaded"); // Create Form
});
